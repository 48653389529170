import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SignUp from "../components/SignUp"

const ImprintPage = () => (
  <Layout>
    <SEO title="Zur App | HeyPixie" keywords={[`startup`, `gebraucht verkaufen`, `inventar`, 'digital']} />
    <Navbar />
    <SignUp />
    <Footer />
  </Layout>
)

export default ImprintPage
