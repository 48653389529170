import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { DARK_BLUE, WRAPPER_WIDTH, TABLET_MIN_WIDTH, GREY_25, GREY_70 } from './config'
import * as logo from '../images/logo_white.png'
import PrimaryButtonBlue from './PrimaryButtonBlue'

const Footer = () => {
  return (
    <OuterWrapper>
      <Wrapper>
        <Box>
          <Logo />
          &copy; 2019 heypixie.de
          <Links>
            <LinkItem><Link to='/impressum'>Impressum</Link></LinkItem>
          </Links>
        </Box>
      </Wrapper>
    </OuterWrapper>
  )
}

const OuterWrapper = styled.section`
  background-color: ${GREY_25};
  color: white;
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 72px 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    padding: 32px 24px;
  }
`

const Box = styled.div`
  max-width: 560px;
  text-align: center;
  margin: 0 auto;
`

const Logo = styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 80px;
  display: block;
  width: 100%;
`

const Links = styled.ul`
  margin-top: 16px;
`

const LinkItem = styled.li`
  a {
    color: ${GREY_70};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

export default Footer
