import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import useWindowScrollPosition from '@rehooks/window-scroll-position'

import { DARK_BLUE, WRAPPER_WIDTH, TABLET_MIN_WIDTH, GREY_90 } from './config'
import * as logo from '../images/logo_white.png'
import * as logoDark from '../images/logo_violet.png'
import PrimaryButton from './PrimaryButton'
import PrimaryButtonBlue from './PrimaryButtonBlue'

const Navbar = () => {
  let position = { x: 0, y: 0 }
  if (typeof window !== `undefined`) {
    position = useWindowScrollPosition({ throttle: 100 })
  }
  const isFixed = position.y > 0
  const Button = isFixed ? PrimaryButtonBlue : PrimaryButton

  return (
    <React.Fragment>
      {isFixed && <Placeholder />}
      <OuterWrapper fixed={isFixed}>
        <Wrapper fixed={isFixed}>
          <Logo fixed={isFixed} to='/' />
          <Button to='/app'>Zur App</Button>
        </Wrapper>
      </OuterWrapper>
    </React.Fragment>
  )
}

const Placeholder = styled.div`
  background-color: ${DARK_BLUE};
  height: 80px;
`

const OuterWrapper = styled.header`
  background-color: ${DARK_BLUE};

  ${({ fixed }) => fixed && `
    background-color: white;
    border-bottom: 1px solid ${GREY_90};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
  `}
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 16px 32px;
  display: flex;
  align-content: center;
  align-items: center;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    padding: 16px 24px;
  }

  ${({ fixed }) => fixed && `
    padding: 8px 32px;

    @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
      padding: 8px 32px;
    }
  `}
`

const Logo = styled(props => <Link {...props} />)`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  flex-grow: 2;
  height: 48px;
  display: block;

  ${({ fixed }) => fixed && `
    background-image: url(${logoDark});
  `}
`

export default Navbar
