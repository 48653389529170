import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { GREEN } from './config'

const PrimaryButton = ({ to, href, children, asButton, ...props }) => {
  if (href) {
    return (
      <PureLink href={href}>{children}</PureLink>
    )
  }
  if (asButton) {
    return <StyledButton {...props}>{children}</StyledButton>
  }
  return (
    <StyledLink to={to}>{children}</StyledLink>
  )
}

const StyledButton = styled.button`
  text-decoration: none;
  color: white;
  padding: 10px 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
  background-color: ${GREEN};
  outline: none;
  border: 0;
  font-family: 'Muli', sans-serif;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: white;
    color: ${GREEN};
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: white;
  padding: 10px 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
  background-color: ${GREEN};

  &:hover,
  &:active {
    background-color: white;
    color: ${GREEN};
  }
`

const PureLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: white;
  padding: 10px 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
  background-color: ${GREEN};

  &:hover,
  &:active {
    background-color: white;
    color: ${GREEN};
  }
`

export default PrimaryButton
