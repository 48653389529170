import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { DARK_BLUE, GREEN } from './config'

const PrimaryButtonBlue = ({ to, href, children }) => {
  if (href) {
    return (
      <PureLink href={href}>{children}</PureLink>
    )
  }

  return (
    <StyledLink to={to}>{children}</StyledLink>
  )
}

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: white;
  padding: 10px 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
  background-color: ${DARK_BLUE};

  &:hover,
  &:active {
    background-color: white;
    color: ${DARK_BLUE};
  }
`

const PureLink = styled.a`
  text-decoration: none;
  color: white;
  padding: 10px 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
  background-color: ${DARK_BLUE};

  &:hover,
  &:active {
    background-color: ${GREEN};
  }
`

export default PrimaryButtonBlue
