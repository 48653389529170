import React, { useState } from "react"
import styled from "styled-components"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Link } from "gatsby"

import { DARK_BLUE, WRAPPER_WIDTH, TABLET_MIN_WIDTH, GREEN, GREY_40 } from './config'
import Phone from './Phone'
import singleItem from '../images/screenshots/single_item.png'
import PrimaryButton from './PrimaryButton'

const OPTIONS = [
  'weniger konsumieren',
  'nachhaltiger konsumieren',
  'Konsumverhalten besser verstehen',
  'Gegenstände smart verkaufen',
  'Gegenstände unkompliziert verleihen'
  ]

const SignUp = () => {
  const [selectedOption, setOption] = useState([])
  const [showNextPage, setShowNextPage] = useState(false)
  const [mailchimpResult, setMailchimpResult] = useState(null)
  const [email, setEmail] = useState('')

  const _handleSubmit = async (e) => {
    e.preventDefault()
    const result = await addToMailchimp(email, { USAGE_REAS: selectedOption.join(', ') })
    setMailchimpResult(result)
  }

  const selectOption = (option) => {
    if (selectedOption.includes(option)) {
      setOption([...(selectedOption.filter(o => o !== option))])
    } else {
      setOption([...selectedOption, option])
    }
  }

  return (
    <OuterWrapper>
      <Wrapper>
        {!showNextPage && <React.Fragment>
          <Headline>
            Was ist dein Ziel?
          </Headline>
          <Text>
            Wir wollen verstehen, warum du die HeyPixie App verwenden möchtest. 
            Das hilft uns, unsere Features noch besser an deinen Bedürfnissen 
            auszurichten. Außerdem sind wir neugierig, zu hören, welche Ziele du 
            hast und welche Ideale dich antreiben.
          </Text>
          <Options>
            {OPTIONS.map((option) => {
              return (
                <Option key={option} selected={selectedOption.includes(option)} onClick={selectOption.bind(null, option)}>{option}</Option>
              )
            })}
          </Options>
          <ButtonRight>
            <PrimaryButton disabled={selectedOption.length === 0} asButton onClick={() => setShowNextPage(true)}>
              Weiter
            </PrimaryButton>
          </ButtonRight>
        </React.Fragment>}
        {showNextPage && <form onSubmit={_handleSubmit}>
          {!mailchimpResult && <Headline>
            Work In Progress!
          </Headline>}
          <Text>
            {(mailchimpResult && mailchimpResult.result !== 'success' && <div dangerouslySetInnerHTML={{__html: mailchimpResult}} />) ||
              (mailchimpResult && mailchimpResult.result === 'success' && <div>Vielen Dank für deine Anmeldung.
                Damit alles datenschutzkonform abläuft, musst du deine E-Mail ({email}) nochmal bestätigen.</div>)
              || <div>
              Wir müssen dir etwas verraten: Die App ist noch nicht fertig. 
              Gerade arbeiten wir aber mit Hochdruck an der Fertigstellung 
              der ersten Version von HeyPixie. Wir freuen uns, wenn du dich in 
              unseren Mailverteiler einträgst. Dann können wir dir nämlich 
              sofort Bescheid geben, wenn die HeyPixie App da ist.
            </div>}
          </Text>
          {!mailchimpResult && <Input type="email" autoFocus onChange={(e) => setEmail(e.target.value)} value={email} placeholder="E-Mail" />}
          {!mailchimpResult && <ButtonRight>
            <PrimaryButton disabled={!email} asButton type="submit">
              Abschicken
            </PrimaryButton>
          </ButtonRight>}
          {mailchimpResult && <ButtonCenter><PrimaryButton to='/'>
            Zur Startseite
          </PrimaryButton></ButtonCenter>}
        </form>}
      </Wrapper>
    </OuterWrapper>
  )
}

const OuterWrapper = styled.header`
  background-color: ${DARK_BLUE};
`

const Input = styled.input`
  box-sizing: border-box;
  padding: 16px;
  width: 100%;
  background: white;
  border: 0;
  font-family: 'Muli', sans-serif;
  font-size: 18px;
  border-radius: 4px;
  margin-bottom: 16px;
  outline: none;
  color: ${GREY_40};
`

const Wrapper = styled.div`
  max-width: 560px;
  box-sizing: border-box;
  padding: 72px 32px 100px 32px;
  color: white;
  margin: 0 auto;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    display: block;
    padding: 32px 24px 64px 24px;
    text-align: center;
  }
`

const ButtonRight = styled.div`
  text-align: right;
`

const ButtonCenter = styled.div`
  text-align: center;
`

const Headline = styled.h1`
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 32px;
  text-align: center;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    font-size: 36px;
    line-height: 44px;
  }
`

const Text = styled.div`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 32px;
  text-align: center;
  strong {
    font-weight: 700;
  }

  a {
    color: white;
  }
`

const Options = styled.div`
  margin-bottom: 32px;
`

const Option = styled.button`
  text-decoration: none;
  color: rgba(255,255,255,0.6);
  background-color: transparent;
  border: 1px solid rgba(255,255,255,0.4);
  padding: 10px 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 4px;
  outline: none;
  font-family: 'Muli', sans-serif;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-bottom: 8px;

  ${({ selected }) => selected && `
    border-color: white;
    color: white;
    background-color: ${GREEN};
  `}

  &:hover,
  &:active {
    background-color: white;
    color: ${GREEN};
  }
`

export default SignUp
