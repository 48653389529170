import React from "react"
import styled from "styled-components"

import { TABLET_MIN_WIDTH } from './config'
import * as phoneBlue from '../images/phone_blue.png'
import * as phoneGrey from '../images/phone_grey.png'

const Phone = ({ image, isGrey, title, showBlock, autoWidth }) => {
  return (
    <OuterWrapper showBlock={showBlock}>
      <PhoneWrapper isGrey={isGrey} autoWidth={autoWidth}>
        <Img src={image} alt={title || 'Preview'} />
        <PhoneImg />
      </PhoneWrapper>
    </OuterWrapper>
  )
}

export default Phone

// 1304 × 2716
const PhoneWrapper = styled.div`
  width: ${({ autoWidth }) => autoWidth ? '100%' : '350px'};
  max-width: 100%;
  padding-top: 208.5%;
  display: inline-block;
  filter: drop-shadow(6px 6px 16px rgba(0,0,0,0.5));
  position: relative;
`

const OuterWrapper = styled.div`
  display: ${({ showBlock }) => showBlock ? 'block' : 'inline-block'};
  padding: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    display: block;
  }
`

const Img = styled.img`
  position: absolute;
  top: 7%;
  left: 6.2%;
  width: 87%;
  height: 90.2%;
`

const PhoneImg = styled.div`
  position: absolute;
  background-image: url(${({ isGrey }) => isGrey ? phoneGrey : phoneBlue});
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
